import Image from "next/image";
import { Text, Heading, AspectRatio, Box } from "@chakra-ui/react";

import { RowContainer } from "components/layout/RowContainer";

export const AppInformation = ({
  flipped,
  heading = "Our app",
  text = `We're excited to announce that there will be a brand new app in the near future! This new app will make it even easier for both personal trainers and their clients to manage their fitness schedules, track their progress, and stay motivated on-the-go. With a user-friendly interface and powerful features, our app will be the ultimate fitness companion for anyone looking to achieve their fitness goals. Stay tuned for more updates and get ready to experience the future of fitness with Make Time Fit Ltd!`,
  img = "https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/Splash%20Screen.png",
  imgAlt = "Make Time Fit App Splash Screen",
  ...props
}) => (
  <RowContainer flipped={flipped} {...props}>
    <Box>
      <Heading
        as="h3"
        fontSize="2xl"
        fontWeight="bolder"
        mb={15}
        textTransform="uppercase"
        color="brand.100"
      >
        {heading}
      </Heading>
      <Text fontSize="lg">{text}</Text>
    </Box>
    {img && (
      <AspectRatio
        position="relative"
        w="full"
        ratio={1 / 1}
        minWidth={["80%", , , 450]}
        maxH={450}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
        >
          <Image layout="fill" src={img} alt={imgAlt} objectFit="contain" />
        </div>
      </AspectRatio>
    )}
  </RowContainer>
);
