import { RowContainerStack } from "components/layout/";

const text = `For clients, Make Time Fit Ltd provides a motivational tool that tracks their progress and helps them stay on track with their fitness goals. Best of all, our platform is judgement-free and supportive, making it easy for clients to stay motivated and focused on achieving their fitness goals. Join [company name] today and experience the future of fitness!`;

export const ClientInfoBlock = ({ content }) => (
  <RowContainerStack
    heading="Info for people"
    text={text}
    img="https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/mat_stretch.jpg"
    flipped
  />
);
