import { Flex, VStack } from '@chakra-ui/react';
import Image from 'next/image';

export const RowBlocks = ({ firstImg, firstChild, secondImg, secondChild }) => (
  <Flex direction={{ base: 'column', md: 'row' }} w="full">
    <Flex
      w={{ base: 'full', md: '50%' }}
      flex={1}
      bgSize="cover"
      bgPos="center"
      justifyContent="center"
      position="relative"
    >
      <Image
        src={firstImg}
        layout="fill"
        objectFit="cover"
        position="absolute"
        loading="eager"
        style={{
          zIndex: -1,
          filter: 'blur(8px)',
          '-webkit-filter': 'blur(8px)',
        }}
      />
      <VStack
        py={{ base: 25, lg: 100 }}
        maxWidth={{ base: '80%', md: '50%' }}
        m="auto"
        justifyContent="center"
        h="full"
      >
        {firstChild}
      </VStack>
    </Flex>

    <Flex
      w={{ base: 'full', md: '50%' }}
      flex={1}
      bgSize="cover"
      bgPos="center"
      justifyContent="center"
      position="relative"
    >
      <Image
        src={secondImg}
        layout="fill"
        objectFit="cover"
        position="absolute"
        loading="eager"
        style={{
          zIndex: -1,
          zIndex: -1,
          filter: 'blur(8px)',
          '-webkit-filter': 'blur(8px)',
        }}
      />
      <VStack
        py={{ base: 25 }}
        maxWidth={{ base: '80%', md: '50%' }}
        m="auto"
        justifyContent="center"
        h="full"
      >
        {secondChild}
      </VStack>
    </Flex>
  </Flex>
);
