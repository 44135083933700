import { useRouter } from "next/router";

import { BannerSplit } from "components/layout/";

const BannerText = `Everyone who has an idea of being healthy, 
with an emphasis on those people who always say they don't 
have time to make healthy meals, or don't have the motivation yet. 
Make Time Fit aims to make health and exercise fit into the client's time frame.`;

const HomeSplitBanner = () => {
  const router = useRouter();
  return (
    <BannerSplit
      img="https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/secondary_logo_1.png"
      imgAlt="Make Time Fit ltd"
      titleOne="Making time fit for you"
      text={BannerText}
      btnText="Sign up now"
      btnFunc={() => router.push("/#sign-up", null, { scroll: true })}
      justify="center"
    />
  );
};

export default HomeSplitBanner;
