import { RowContainerStack } from "components/layout/";

const text = `With our seamless booking system, trainers can easily schedule and manage their sessions while clients can easily book and manage their appointments. Our platform also includes a user-friendly interface that helps trainers track their clients' progress, customize their workouts, and provide personalized feedback.`;

export const BookingToolInformation = () => (
  <RowContainerStack
    heading="Our booking tool"
    text={text}
    img="https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/dashboard.png"
    imgAlt={"Booking tool information"}
  />
);
