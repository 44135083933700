import {
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { lighten } from "polished";

import { colors } from "ui/theme";

export const BannerWithBackground = ({
  bgImg,
  heading,
  btnOneText,
  btnOneFunc,
  ...props
}) => (
  <Flex
    w={"full"}
    h={useBreakpointValue({ base: "100vh", lg: "90vh" })}
    backgroundImage={bgImg}
    backgroundSize={"cover"}
    backgroundPosition={"right"}
  >
    <VStack
      w={"full"}
      justify={props.justify ? props.justify : "end"}
      alignItems="flex-start"
      px={useBreakpointValue({ base: 4, md: 16 })}
      bgGradient={"linear(to-r, brand.200, transparent)"}
    >
      <Stack maxW={"2xl"} spacing={6}>
        <Text
          color={"white"}
          fontWeight={700}
          lineHeight={1.2}
          fontSize={useBreakpointValue({ base: "3xl", md: "4xl" })}
        >
          {heading}
        </Text>
        <Stack direction={"row"}>
          {btnOneText && btnOneFunc && (
            <Button
              bg={"brand.100"}
              rounded={"full"}
              color={"white"}
              _hover={{ bg: lighten(0.2, colors.brand["100"]) }}
              onClick={btnOneFunc}
            >
              {btnOneText}
            </Button>
          )}
          {props.btnTwoText && props.btnTwoFunc && (
            <Button
              bg={"brand.300"}
              rounded={"full"}
              color={"white"}
              _hover={{ bg: lighten(0.2, colors.brand["300"]) }}
              onClick={props.btnTwoFunc}
            >
              {props.btnTwoText}
            </Button>
          )}
        </Stack>
      </Stack>
    </VStack>
  </Flex>
);
