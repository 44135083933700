import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
} from "@chakra-ui/react";

export const BannerSplit = ({
  img,
  imgAlt,
  titleOne = "Write once,",
  titleTwo = null,
  text,
}) => (
  <Container as={Flex} maxW={"7xl"} minH="90vh">
    <Stack
      align={"center"}
      py={{ base: 10, md: 0 }}
      direction={{ base: "column", md: "row" }}
      justify="space-between"
    >
      <Stack flex={1} spacing={{ base: 5 }}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "3xl", md: "5xl" }}
        >
          <Text
            as={"span"}
            position={"relative"}
            color="brand.font"
            _after={{
              content: "''",
              width: "full",
              height: "10%",
              position: "absolute",
              bottom: 1,
              left: 0,
              bg: "brand.200",
              zIndex: -1,
            }}
          >
            {titleOne}
          </Text>
          {titleTwo && (
            <>
              <br />
              <Text
                as={"span"}
                color={"brand.100"}
                fontSize={{ base: "xl", sm: "2xl", lg: "4xl" }}
              >
                {titleTwo}
              </Text>
            </>
          )}
        </Heading>
        <Text color={"brand.font"}>{text}</Text>
        {/* <Stack
          spacing={{ base: 4, sm: 6 }}
          direction={{ base: "column", sm: "row" }}
        >
          <Button
            rounded={"md"}
            fontWeight={"normal"}
            px={6}
            colorScheme={"red"}
            bg={"brand.100"}
            _hover={{ bg: darken(0.2, colors.brand[100]) }}
            _active={{ bg: lighten(0.2, colors.brand[100]) }}
            onClick={btnFunc}
          >
            {btnText}
          </Button>
        </Stack> */}
      </Stack>
      <Flex
        flex={1}
        justify={"center"}
        align={"center"}
        position={"relative"}
        w={"full"}
      >
        <Box
          position={"relative"}
          height={450}
          width={"full"}
          overflow={"hidden"}
        >
          <Image
            alt={imgAlt}
            fit={"contain"}
            align={"center"}
            w={"100%"}
            h={"100%"}
            src={img}
            loading="eager"
          />
        </Box>
      </Flex>
    </Stack>
  </Container>
);
