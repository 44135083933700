import { Stack, Fade, Box } from '@chakra-ui/react';
import { useInView } from 'react-cool-inview';

export const RowContainer = ({ flipped, children, ...props }) => {
  const { observe, inView } = useInView({
    // Stop observe when the target enters the viewport, so the "inView" only triggered once
    unobserveOnEnter: true,
    // For better UX, we can grow the root margin so the image will be loaded before it comes to the viewport
    rootMargin: '50px',
  });
  return (
    <Box ref={observe}>
      {inView && (
        <Fade in={inView}>
          <Stack
            alignItems="flex-end"
            spacing={{
              base: 15,
              lg: 50,
            }}
            direction={
              props.direction
                ? props.direction
                : {
                    base: 'column',
                    lg: flipped ? 'row-reverse' : 'row',
                  }
            }
            maxW="6xl"
            m="auto"
            {...props}
          >
            {children}
          </Stack>
        </Fade>
      )}
    </Box>
  );
};
