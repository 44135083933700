import { AppInformation } from './AppInformation';
import { ClientInfoBlock } from './ClientInfoCol';
import { BookingToolInformation } from './BookingToolInformation';

const InformationBlock = ({}) => (
  <>
    <BookingToolInformation />
    <ClientInfoBlock />
    <AppInformation />
  </>
);

export default InformationBlock;
