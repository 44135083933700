import Image from 'next/image';
import { Text, Heading, AspectRatio, Box } from '@chakra-ui/react';

import { RowContainer } from './RowContainer';

export const RowContainerStack = ({
  flipped,
  heading,
  text,
  img,
  imgAlt,
  ...props
}) => (
  <RowContainer flipped={flipped} {...props}>
    <Box>
      <Heading
        as="h3"
        fontSize="2xl"
        fontWeight="bolder"
        mb={15}
        textTransform="uppercase"
        color="brand.100"
      >
        {heading}
      </Heading>
      <Text fontSize="lg">{text}</Text>
    </Box>
    {img && (
      <AspectRatio
        position="relative"
        w="full"
        ratio={1 / 1}
        minWidth={['80%', , , 450]}
        // maxH={450}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
        >
          <Image
            layout="fill"
            src={img}
            alt={imgAlt}
            objectFit="cover"
            objectPosition="top left"
          />
        </div>
      </AspectRatio>
    )}
  </RowContainer>
);
